<template>
  <div class="" >
    <div v-if="!mobileView" @mouseover="openDrawer()" @mouseleave="closeDrawer()" id="mySidenav" class="sidenav bg-white cursor-pointer ">
      
      <div class="pt-4 navContainer" >
        <div 
          v-for="item in menuItems"
          :key="item.path"
          @click.stop="routeTo(item)"
          class="h-12 ico_list d_icon_box items-center mb-3 hover:shadow-lg text-gray3 hover:text-primary"
          
        >
          <div class="d_icon_child">
            <div :class="item.meta.selected ? 'text-primary ' : ''" class="rounded heading-2">
              <i style="height:30px; width:30px" :class="item.meta.iconClass"></i>
              <span class="tooltiptext heading-4 bg-transparent whitespace-nowrap rounded">{{ item.meta.title }}</span>
            </div>
          </div>
          <!-- <span :class="item.meta.selected ? 'text-primary ' : ''" class="d_items_text  font-bold">{{ item.meta.title }}</span> -->
        </div>
      </div>
    </div>
    <div v-else class="sv_sidenav_box">
      <div v-click-overLay="closeLeftNavDrawer" id="sv_smySidenav" class="sv_sidenav bg-white">
        <div class="pt-4">
        <div 
          v-for="item in menuItems"
          :key="item.path"
          @click="routeTo(item)"
          class="h-12 ico_list d_icon_box items-center mb-4 hover:shadow-lg text-gray3 hover:text-primary" 
        >
          <div class="d_icon_child">
            <div :class="item.meta.selected ? 'text-primary ' : ''" class="rounded heading-2">
              <i style="height:30px; width:30px" :class="item.meta.iconClass"></i>
            </div>
          </div>
          <span :class="item.meta.selected ? 'text-primary ' : ''" class="d_items_text  font-bold">{{ item.meta.title }}</span>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import {FilterPermissions} from '@/utils/Permissions.js'
import Vue from 'vue'
Vue.directive('click-overLay', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  }
})
// import colors from '@/assets/colors.json'
import router from '@/router/index.js'
export default {
  components: {},
  name: "leftNavbar",
  data() {
    return {
      getPermission: FilterPermissions(''),
      showCall: false,
      mobileView: false,
      extraSmall: false,
      drawerIsOpen: false,
      menuItems: [
        {
          path: '/dashboard',
          name: 'dashboard1',
          isShowPermission: true,
          permissionSlug: '',
          meta: {selected: false, routIconSize: {height: '40px', width: '40px', color: ''},
          iconClass: 'fa-solid fa-house', title: 'Home', loadPage: 'dashboard'},
        },
        {
          path: '/schedule',
          name: 'schedule',
          isShowPermission: true,
          permissionSlug: '',
          meta: {selected: false, routIconSize: {height: '40px', width: '40px', color: ''},
          iconClass: 'fa-solid fa-truck', title: 'Schedule', loadPage: 'schedule'},
        },
        {
          path: '/customer',
          name: 'customer',
          isShowPermission: false,
          permissionSlug: 'customer',
          meta: {selected: false, routIconSize: {height: '40px', width: '40px', color: ''},
          iconClass: 'fa-solid fa-users', title: 'Cust.', loadPage: 'customer'},
        },
        {
          path: '/lead',
          name: 'lead',
          isShowPermission: false,
          permissionSlug: 'lead',
          meta: {selected: false, routIconSize: {height: '40px', width: '40px', color: ''},
          iconClass: 'fas fa-comment-dollar', title: 'Lead', loadPage: 'lead'},
        },
        {
          path: '/job',
          name: 'job',
          isShowPermission: false,
          permissionSlug: 'job',
          meta: {selected: false, routIconSize: {height: '40px', width: '40px', color: ''},
          iconClass: 'fa-solid fa-briefcase', title: 'Job', loadPage: 'job'},
        },
        {
          path: '/visit',
          name: 'visit',
          isShowPermission: true,
          permissionSlug: '',
          meta: {selected: false, routIconSize: {height: '50px', width: '50px', color: ''},
          iconClass: 'fas fa-business-time', title: 'Visit', loadPage: 'visit'},
        },
        {
          path: '/task',
          name: 'Tasks',
          isShowPermission: false,
          permissionSlug: 'task',
          meta: {selected: false, routIconSize: {height: '50px', width: '50px', color: ''},
          iconClass: 'fa-solid fa-clipboard-list', title: 'Task', loadPage: 'Task'},
        },
        {
          path: '/request',
          name: 'request',
          isShowPermission: false,
          permissionSlug: 'request',
          meta: {selected: false, routIconSize: {height: '50px', width: '50px', color: ''},
          iconClass: 'fa-solid fa-code-pull-request', title: 'Request', loadPage: 'request'},
        },
        {
          path: '/invoice',
          name: 'invoice',
          isShowPermission: false,
          permissionSlug: 'invoice',
          meta: {selected: false, routIconSize: {height: '40px', width: '40px', color: ''},
          iconClass: 'fa-solid fa-file-invoice-dollar', title: 'Invoice', loadPage: 'invoice'},
        },
        {
          path: '/quotation',
          name: 'quotation',
          isShowPermission: false,
          permissionSlug: 'quote',
          meta: {selected: false, routIconSize: {height: '40px', width: '40px', color: ''},
          iconClass: 'fab fa-quora', title: 'Quote', loadPage: 'quotation'},
        },
        {
          path: '/payment',
          name: 'payment',
          isShowPermission: true,
          permissionSlug: '',
          meta: {selected: false, routIconSize: {height: '40px', width: '40px', color: ''},
          iconClass: 'fa-solid fa-dollar-sign', title: 'Payment', loadPage: 'payment'},
        },
        {
          path: '/shift',
          name: 'shift',
          isShowPermission: false,
          permissionSlug: 'shift',
          meta: {selected: false, routIconSize: {height: '40px', width: '40px', color: ''},
          iconClass: 'fa-solid fa-clock', title: 'Shift', loadPage: 'shift'},
        },
        {
          path: '/expense',
          name: 'Expense',
          isShowPermission: false,
          permissionSlug: 'expense',
          meta: {selected: false, routIconSize: {height: '50px', width: '50px', color: ''},
          iconClass: 'fa-solid fa-money-bill-transfer', title: 'Expense', loadPage: 'Expense'},
        },
        {
          path: '/activity',
          name: 'activity',
          isShowPermission: true,
          permissionSlug: '',
          meta: {selected: false, routIconSize: {height: '40px', width: '40px', color: ''},
          iconClass: 'fa-solid fa-chart-line', title: 'Activity', loadPage: 'activity'},
        },
        {
          path: '/setting',
          name: 'Setting',
          isShowPermission: true,
          permissionSlug: '',
          meta: {selected: false, routIconSize: {height: '40px', width: '40px', color: ''},
          iconClass: 'fa-solid fa-gear', title: 'Setting', loadPage: 'Setting'},
        }
      ]
    };
  },
  created() {
    this.resizeWindowHandler()
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  mounted() {
    this.resizeWindowHandler();
     
    router.afterEach(() => {
      setTimeout(() => {
        this.setNavigation(this.$route.meta.loadPage)
      }, 200);
    })
    setTimeout(() => {
      this.setNavigation(this.$route.meta.loadPage)
    }, 500);
  },
  watch: {
  },
  methods: {
    closeLeftNavDrawer () {
      document.getElementById("overlay").style.display = "none";
      document.getElementById("sv_smySidenav").style.minWidth = "0px";
    },
    closeDrawer() {
    },
    openDrawer() {
    },
    routeTo(path) {
    console.log('path', path)
      let temp = FilterPermissions(path.permissionSlug)
      if (path.isShowPermission) {
        this.$router.push({ name: path.name }).catch(()=>{});
      } else {
        if (temp) {
          this.$router.push({ name: path.name }).catch(()=>{});
        } else {
          this.$store.dispatch('ShowSubPopup', {status: true, title: `You don't have permission to open ` + path.meta.title})
        }
      }
      if (this.mobileView) {
        this.closeLeftNavDrawer()
      }
      for (let index = 0; index < this.menuItems.length; index++) {
        // if (this.$route.name === this.menuItems[index].name) {
        if (this.$route.meta.loadPage === this.menuItems[index].meta.loadPage) {
          this.menuItems[index].meta.selected = true;
          // this.menuItems[index].meta.routIconSize.color = colors.white;
        } else {
          this.menuItems[index].meta.selected = false;
          // this.menuItems[index].meta.routIconSize.color = colors.gray2;
        }
      }
    },
    setNavigation (value) {
      if (this.$route.meta.title !== '') {
        if (this.$route.meta.title !== 'Job Detail' && this.$route.meta.title !== 'Quote Detail' && this.$route.meta.title !== 'Invoice Detail' && this.$route.meta.title !== 'Payment Detail') {
          document.title = this.$route.meta.title
        }
      }
      for (let index = 0; index < this.menuItems.length; index++) {
        // if (this.$route.name === this.menuItems[index].name) {
          // console.log("vvvvvvvv" value , this.routesList[index].meta);
        if (value === this.menuItems[index].meta.loadPage) {
          this.menuItems[index].meta.selected = true;
        } else {
          this.menuItems[index].meta.selected = false;
        }
      }
      console.log(this.menuItems[0].meta.selected);
    },
    resizeWindowHandler() {
      if (window.innerWidth < 1024) {
        this.mobileView = true;
      } else {
        this.mobileView = false;
      }
      if (window.innerWidth < 600) {
        this.extraSmall = true;
      } else {
        this.extraSmall = false;
      }
    },
  },
  beforeDestroy () {
  }
};
</script>
<style scoped>
#mySidenav {
  width: 55px !important;
  transition: width 0.5s;
}
#mySidenav:hover {
  /* width: 300px !important; */
}
#mySidenav:hover .ico_list{
  display: flex;
  align-items: center;
    /* --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / .1), 0 2px 4px -2px rgb(0 0 0 / .1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow); */
}


.d_icon_child {
  position: relative;
}

.tooltiptext {
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
    width: 52px;
    height: 20px;
    color: #fff;
    text-align: center;
    padding: 0px 0px;
    background-color: #0000009c;
    position: absolute;
    z-index: 1;
    top: -39%;
    font-size: 12px;
    left: 61px;
    margin-left: -60px;
}


.d_icon_child:hover .tooltiptext {
  visibility: visible;
}
.navContainer  {
  height: 100%; 
  width: 100%;
  overflow-y: auto; 
  scrollbar-width: none;
  overflow-x: visible;
}
</style>
