import axios from 'axios'
import * as config from '../config.js'
// import {getCookies} from '@/utils/cookies'
/**
 * init class API
 * @param opt
 * @returns {MyJobApi}
 * @constructor
 */

function MyJobApi (opt) {
  if (!(this instanceof MyJobApi)) {
    return new MyJobApi(opt)
  }
  opt = opt || {}
  this.classVersion = '1.0.0'
  console.log('opt', opt);
  this.api = axios.create({
    baseURL: config.API_ROOT,
    timeout: 9000000
  })
}

/**
 * Request to the server,
 * You fixed: https://gist.github.com/pranavrajs/66bccee3f8ba100742a1273db6f587af
 * @param url
 * @param callback
 * @returns {axios.Promise}
 * @private
 */

MyJobApi.prototype._requestAuthPost = function (url, contentType, data, callback) {
  var headers = {
    Accept: 'application/json',
    'Content-Type': contentType
  }
  return this.api.post(url, data, headers).then(response => {
    if (response.data) {
      if (typeof callback === 'function') {
        callback()
      }
      return response.data
    } else {
      return {
        success: 0,
        message: response.problem
      }
    }
  })
}

MyJobApi.prototype._requestDataPost = function (url, contentType, data, callback) {
  var headers = {
    // 'Api-Key': getCookies('jobAuthToken'),
    Accept: 'application/json',
    'Content-Type': contentType
  }
  // this.api.defaults.headers.post.APIKEY = 'Bearer ' + getCookies('jobAuthToken')
  // this.api.defaults.headers.common.APIKEY = 'Bearer ' + getCookies('jobAuthToken')
  return this.api.post(url, data, headers).then(response => {
    if (response.data) {
      if (typeof callback === 'function') {
        callback()
      }
      return response.data
    } else {
      console.log('check 1');
      return {
        success: 0,
        message: response.problem
      }
    }
  }).catch(error => {
    console.log('check 2', error);
    let errorMessage = error.message
    if (error.response.data) {
      errorMessage = error.response.data.message
    }
    return {
      success: 0,
      message: errorMessage
    }
  })
}

MyJobApi.prototype._requestDataGet = function (url, contentType, data, callback) {
  var headers = {
    Accept: 'application/json',
    'Content-Type': contentType
  }
  return this.api.get(url + data, headers).then(response => {
    if (response.data) {
      if (typeof callback === 'function') {
        callback()
      }
      return response.data
    } else {
      return {
        success: 0,
        message: response.problem
      }
    }
  }).catch(error => {
    return {
      success: 0,
      message: error.message
    }
  })
}

MyJobApi.prototype._requestDataPostFileAttachment = function (url, contentType, data, callback) {
  var headers = {
    Accept: 'application/json',
    'Authorization': 'Bearer 23432567654',
    'Content-Type': contentType,
    responseType: 'blob'
  }
  return this.api.post(url, data, headers).then((response) => {
    if (response.data) {
      if (typeof callback === 'function') {
        callback()
      }
      return response.data
    } else {
      return {
        success: 0,
        message: response.problem
      }
    }
  }).catch(error => {
    return {
      success: 0,
      message: error.message
    }
  })
}

MyJobApi.prototype.login = async function (data, callback) {
  const requestUrl = '/api/v1/auth/webLogin'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.DirectLogin = async function (data, callback) {
  const requestUrl = '/api/v1/auth/fromSAwebLogin'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.forgotPinOtpSend = async function (data, callback) {
  const requestUrl = '/api/v1/auth/forgotPinOtpSend'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.forgotPinVerifyOtp = async function (data, callback) {
  const requestUrl = '/api/v1/auth/forgotPinVerifyOtp'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.updateLoginPin = async function (data, callback) {
  const requestUrl = '/api/v1/auth/updateLoginPin'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.VerifyAppPin = async function (data, callback) {
  const requestUrl = '/api/v1/auth/verifyAppPinWeb'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.cuaromerList = async function (data, callback) {
  const requestUrl = '/api/v1/customer/listWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.saveNewCustomer = async function (data, callback) {
  const requestUrl = '/api/v1/customer/saveWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.addUpdateContact = async function (data, callback) {
  const requestUrl = '/api/v1/contact/saveWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.getJobList = async function (data, callback) {
  const requestUrl = '/api/v1/job/listWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.addCustomerAddress = async function (data, callback) {
  const requestUrl = '/api/v1/customer/saveAddressWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.deleteCustomerAddress = async function (data, callback) {
  const requestUrl = '/api/v1/customer/deleteAddressWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.updateCustomer = async function (data, callback) {
  const requestUrl = '/api/v1/customer/updateWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.getCustomerDetail = async function (data, callback) {
  const requestUrl = '/api/v1/customer/detailWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.getCustomerActivity = async function (data, callback) {
  const requestUrl = '/api/v1/customer/activityWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.getInvoiceList = async function (data, callback) {
  const requestUrl = '/api/v1/invoice/listWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.invoiceDetail = async function (data, callback) {
  const requestUrl = '/api/v1/invoice/detailWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.getPaymentList = async function (data, callback) {
  const requestUrl = '/api/v1/payment/listWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.getItemsList = async function (data, callback) {
  const requestUrl = '/api/v1/setting/item/listWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.settingItemDeleteApi = async function (data, callback) {
  const requestUrl = '/api/v1/setting/item/deleteWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.settingAddNewItem = async function (data, callback) {
  const requestUrl = 'api/v1/setting/item/addWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.settingEditExistItem = async function (data, callback) {
  const requestUrl = 'api/v1/setting/item/updateWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.getSettingCategoryList = async function (data, callback) {
  const requestUrl = 'api/v1/setting/category/listWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.SettingCategoryAddEdit = async function (data, callback) {
  const requestUrl = 'api/v1/setting/category/saveWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.SettingCategoryDetail = async function (data, callback) {
  const requestUrl = 'api/v1/setting/category/detailWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.SettingCategoryDelete = async function (data, callback) {
  const requestUrl = 'api/v1/setting/category/deleteWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.SettingCheckListItemList = async function (data, callback) {
  const requestUrl = 'api/v1/setting/checkListItem/listWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.SettingCheckListItemAdd = async function (data, callback) {
  const requestUrl = 'api/v1/setting/checkListItem/addWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.SettingCheckListItemUpdate = async function (data, callback) {
  const requestUrl = 'api/v1/setting/checkListItem/updateWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.SettingCheckListItemDelete = async function (data, callback) {
  const requestUrl = 'api/v1/setting/checkListItem/deleteWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.SettingCheckListCategoryList = async function (data, callback) {
  const requestUrl = 'api/v1/setting/checkListCategory/listWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.SettingCheckListCategoryDetail = async function (data, callback) {
  const requestUrl = 'api/v1/setting/checkListCategory/detailWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.SettingCheckListCategoryDelete = async function (data, callback) {
  const requestUrl = 'api/v1/setting/checkListCategory/deleteWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.SettingCheckListCategorySave = async function (data, callback) {
  const requestUrl = 'api/v1/setting/checkListCategory/saveWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.SettingTaxListing = async function (data, callback) {
  const requestUrl = 'api/v1/setting/tax/listWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.getTaxList = async function (data, callback) {
  const requestUrl = '/api/v1/setting/tax/listWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.addNewTax = async function (data, callback) {
  const requestUrl = '/api/v1/setting/tax/addWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.SettingDeleteTax = async function (data, callback) {
  const requestUrl = '/api/v1/setting/tax/deleteWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.checkTaxLink = async function (data, callback) {
  const requestUrl = '/api/v1/setting/tax/checkTaxLinkWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.UpdateTax = async function (data, callback) {
  const requestUrl = '/api/v1/setting/tax/updateWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.createInvoice = async function (data, callback) {
  const requestUrl = '/api/v1/invoice/saveWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.listWithPendingPaymen = async function (data, callback) {
  const requestUrl = '/api/v1/invoice/listWithPendingPaymentWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.paymethodList = async function (data, callback) {
  const requestUrl = '/api/v1/setting/paymethod/listWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.paymentmethodList = async function (data, callback) {
  const requestUrl = '/api/v1/setting/paymethod/listWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.paymentmethodReorder = async function (data, callback) {
  const requestUrl = '/api/v1/setting/paymethod/reOrderWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.SendSupportData = async function (data, callback) {
  const requestUrl = '/api/v1/support/saveWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.SendFeedbackData = async function (data, callback) {
  const requestUrl = '/api/v1/feedback/saveWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.AddNewPermission = async function (data, callback) {
  const requestUrl = '/api/v1/employee/permission/saveWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.AddNewEmployee = async function (data, callback) {
  const requestUrl = '/api/v1/employee/saveWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.UpdateEmployee = async function (data, callback) {
  const requestUrl = '/api/v1/employee/updateWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.checkUserNameExistWeb = async function (data, callback) {
  const requestUrl = '/api/v1/employee/checkUserNameExistWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.sendOTPEmailWeb = async function (data, callback) {
  const requestUrl = '/api/v1/auth/owner/sendOTPEmailWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.getLeadlist = async function (data, callback) {
  const requestUrl = '/api/v1/lead/listWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.getQuotationList = async function (data, callback) {
  const requestUrl = '/api/v1/quotation/listWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

MyJobApi.prototype.addUpdatePaymethod = async function (data, callback) {
  const requestUrl = '/api/v1/setting/paymethod/saveWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.savePaymentRecord = async function (data, callback) {
  const requestUrl = '/api/v1/payment/saveWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.getSiftList = async function (data, callback) {
  const requestUrl = '/api/v1/shift/listByEmployeeWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.getActivitys = async function (data, callback) {
  const requestUrl = '/api/v1/search/activityWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.getSpecificSiftList = async function (data, callback) {
  const requestUrl = 'api/v1/shift/listWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

MyJobApi.prototype.deleteNoteAttachment = async function (data, callback) {
  const requestUrl = 'api/v1/notes/deleteNoteAttachmentWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.addNoteWithAttachment = async function (data, callback) {
  const requestUrl = 'api/v1/notes/addWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.deleteNote = async function (data, callback) {
  const requestUrl = 'api/v1/notes/deleteWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.updateNoteWithAttach = async function (data, callback) {
  const requestUrl = '/api/v1/notes/updateWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.updateJobDetail = async function (data, callback) {
  const requestUrl = '/api/v1/job/jobUpdateWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.updateServiceDetail = async function (data, callback) {
  const requestUrl = '/api/v1/request/updateServiceDetailWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.GeneratereqLinkHash = async function (data, callback) {
  const requestUrl = '/api/v1/request/updateServiceDetailWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.emailSendBokkconf = async function (data, callback) {
  const requestUrl = '/api/v1/email/sendWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.searchContactEmail = async function (data, callback) {
  const requestUrl = '/api/v1/search/contactsWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

MyJobApi.prototype.getUserLocation = async function (data, callback) {
  const requestUrl = 'api/v1/location/lastLocationWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.HashDisAssociationLink = async function (data, callback) {
  const requestUrl = 'api/v1/user/disAssociationLinkCheck'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.HashDisAssociationLinkApprove = async function (data, callback) {
  const requestUrl = 'api/v1/user/confirm/disAssociation'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.HashInvoiceDetail = async function (data, callback) {
  const requestUrl = 'api/v1/user/invoice'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.downloadInvoice = async function (data, callback) {
  const requestUrl = 'api/v1/user/downloadInvPdf'
  const contentType = 'application/json'
  return this._requestDataPostFileAttachment(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.HashPaymentDetail = async function (data, callback) {
  const requestUrl = 'api/v1/user/payment'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.downloadPayment = async function (data, callback) {
  const requestUrl = 'api/v1/user/downloadPaymentPdf'
  const contentType = 'application/json'
  return this._requestDataPostFileAttachment(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.HashQuotationDetail = async function (data, callback) {
  const requestUrl = 'api/v1/user/quotation'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.downloadQuotation = async function (data, callback) {
  const requestUrl = 'api/v1/user/downloadQuotationPdf'
  const contentType = 'application/json'
  return this._requestDataPostFileAttachment(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.HashJobDetail = async function (data, callback) {
  const requestUrl = 'api/v1/user/job'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.downloadJob = async function (data, callback) {
  const requestUrl = 'api/v1/user/downloadJobPdf'
  const contentType = 'application/json'
  return this._requestDataPostFileAttachment(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.saveQuoteDetail = async function (data, callback) {
  const requestUrl = 'api/v1/quotation/saveWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.GetQuoteDetail = async function (data, callback) {
  const requestUrl = 'api/v1/quotation/detailWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.approveQuotes = async function (data, callback) {
  const requestUrl = 'api/v1/quotation/approveWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.deleteQuotation = async function (data, callback) {
  const requestUrl = 'api/v1/quotation/deleteWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
// GLOBAL SEARCHING
MyJobApi.prototype.GlobalSearchCount = async function (data, callback) {
  const requestUrl = 'api/v1/search/searchDataCountWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.GlobalCustomerSearch = async function (data, callback) {
  const requestUrl = 'api/v1/search/customerWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.GlobalInvoiceSearch = async function (data, callback) {
  const requestUrl = 'api/v1/search/invoiceWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.GlobalQuoteSearch = async function (data, callback) {
  const requestUrl = 'api/v1/search/quotationWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.GlobalLeadSearch = async function (data, callback) {
  const requestUrl = 'api/v1/search/leadWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.GlobalPaymentSearch = async function (data, callback) {
  const requestUrl = 'api/v1/search/paymentWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.GlobalJobSearch = async function (data, callback) {
  const requestUrl = 'api/v1/search/jobWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.GlobalShiftSearch = async function (data, callback) {
  const requestUrl = 'api/v1/search/shiftWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.GlobalRequestSearch = async function (data, callback) {
  const requestUrl = 'api/v1/search/requestWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.GlobalTaskSearch = async function (data, callback) {
  const requestUrl = 'api/v1/search/taskWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.GlobalVisitSearch = async function (data, callback) {
  const requestUrl = 'api/v1/search/visitsWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}


MyJobApi.prototype.closeJob = async function (data, callback) {
  const requestUrl = 'api/v1/job/closeWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.reopenJob = async function (data, callback) {
  const requestUrl = 'api/v1/job/reopenJobWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.deleteJob = async function (data, callback) {
  const requestUrl = 'api/v1/job/deleteJobWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.createNewJob = async function (data, callback) {
  const requestUrl = 'api/v1/job/saveWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.addNewJobStepOne = async function (data, callback) {
  const requestUrl = 'api/v1/job/createNewJobWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.updateJobStep2 = async function (data, callback) {
  const requestUrl = 'api/v1/job/updateJobStep2Web'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.updateJobStep3 = async function (data, callback) {
  const requestUrl = 'api/v1/job/updateJobStep3Web'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.updateVisitChecklist = async function (data, callback) {
  const requestUrl = 'api/v1/job/updateVisitCheckListWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.jobTypelist = async function (data, callback) {
  const requestUrl = '/api/v1/setting/jobType/listWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.getToolList = async function (data, callback) {
  const requestUrl = '/api/v1/setting/tool/listWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.addJobVisit = async function (data, callback) {
  const requestUrl = 'api/v1/job/addJobVisitWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.addVisititems = async function (data, callback) {
  const requestUrl = 'api/v1/job/adVisitItemWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.deleteVisitItem = async function (data, callback) {
  const requestUrl = 'api/v1/job/deleteVisitItemWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.updateVisitItem = async function (data, callback) {
  const requestUrl = 'api/v1/job/updateVisitItemWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.visitDelete = async function (data, callback) {
  const requestUrl = 'api/v1/job/visitDeleteWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.addNewShift = async function (data, callback) {
  const requestUrl = '/api/v1/shift/missingShiftWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.startJobVisit = async function (data, callback) {
  const requestUrl = '/api/v1/job/startWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.stopJobVisit = async function (data, callback) {
  const requestUrl = '/api/v1/job/stopWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.addUpdateVisitAssig = async function (data, callback) {
  const requestUrl = '/api/v1/job/addVisitAssigneWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.updateVisitTime = async function (data, callback) {
  const requestUrl = '/api/v1/job/updateVisitTimeWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.visitComplete = async function (data, callback) {
  const requestUrl = '/api/v1/job/visitCompleteWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.deleteSelfie = async function (data, callback) {
  const requestUrl = '/api/v1/job/deleteSelfieWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.adminStartEndJobForUserWeb = async function (data, callback) {
  const requestUrl = '/api/v1/job/adminStartEndJobForUserWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.EditOrgDetail = async function (data, callback) {
  const requestUrl = '/api/v1/organization/updateWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.EditOrgDetailWithLogo = async function (data, callback) {
  const requestUrl = '/api/v1/organization/updateWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.orgnizationDelete = async function (data, callback) {
  const requestUrl = '/api/v1/organization/deleteWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.DeleteEmployee = async function (data, callback) {
  const requestUrl = '/api/v1/employee/deleteWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.addUpdateLead = async function (data, callback) {
  const requestUrl = '/api/v1/lead/saveWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.UpdateLeads = async function (data, callback) {
  const requestUrl = '/api/v1/lead/updateLeadWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.UpdateLeadsStatus = async function (data, callback) {
  const requestUrl = 'api/v1/lead/statusUpdateWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.UpdateLeadsContact = async function (data, callback) {
  const requestUrl = '/api/v1/contact/saveWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.UpdateLeadsAddress = async function (data, callback) {
  const requestUrl = '/api/v1/lead/saveAddressWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.getGlobalCustLeadList = async function (data, callback) {
  const requestUrl = '/api/v1/quotation/custLeadWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.RemoveContacts = async function (data, callback) {
  const requestUrl = '/api/v1/contact/deleteWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.RemoveLeadCustomer = async function (data, callback) {
  const requestUrl = '/api/v1/customer/deleteWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.addNewJobLocationWeb = async function (data, callback) {
  const requestUrl = '/api/v1/job/saveJobLocationWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.ScheduleJobVisit = async function (data, callback) {
  const requestUrl = '/api/v1/schedule/jobVisitWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.UserScheduleJobVisit = async function (data, callback) {
  const requestUrl = '/api/v1/schedule/userJobVisitWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.GetUnScheduleJobsOnly = async function (data, callback) {
  const requestUrl = '/api/v1/schedule/unscheduleJobsListWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.registerOrganizationTemp = async function (data, callback) {
  const requestUrl = '/api/v1/auth/register/saveBasicDetail'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.registerOrganization = async function (data, callback) {
  const requestUrl = '/api/v1/auth/owner/registration'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.CompanySetup = async function (data, callback) {
  const requestUrl = '/api/v1/auth/owner/companySetupWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.checkEmailNumberExist = async function (data, callback) {
  const requestUrl = '/api/v1/auth/register/exist'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.updateEmailSendOtp = async function (data, callback) {
  const requestUrl = '/api/v1/auth/register/updateEmail'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.registerResendOtp = async function (data, callback) {
  const requestUrl = '/api/v1/auth/register/resendOtp'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.registerVerifyOtp = async function (data, callback) {
  const requestUrl = '/api/v1/auth/register/verifyOtp'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.registerSetupPin = async function (data, callback) {
  const requestUrl = '/api/v1/auth/register/setupPin'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.latLongUpdateWeb = async function (data, callback) {
  const requestUrl = '/api/v1/organization/latLongUpdateWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.addressUpdateWeb = async function (data, callback) {
  const requestUrl = '/api/v1/organization/addressUpdateWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.rejectEmpRequest = async function (data, callback) {
  const requestUrl = '/api/v1/employee/rejectWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.specificShiftDelete = async function (data, callback) {
  const requestUrl = 'api/v1/shift/deleteWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.getLastLocationOfAssignEmp = async function (data, callback) {
  const requestUrl = 'api/v1/schedule/getLastLocationOfAssiWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.requestlist = async function (data, callback) {
  const requestUrl = '/api/v1/request/listWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.deleteRequest = async function (data, callback) {
  const requestUrl = '/api/v1/request/deleteRequestWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.saveRequest = async function (data, callback) {
  const requestUrl = 'api/v1/request/saveRequestWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.sheduleRequest = async function (data, callback) {
  const requestUrl = 'api/v1/request/sheduleRequestWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.overviewReq = async function (data, callback) {
  const requestUrl = 'api/v1/request/overviewReqWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.getAccountingCodeList = async function (data, callback) {
  const requestUrl = 'api/v1/expense/accountingCodelistWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.AddUpdateAccountingCode = async function (data, callback) {
  const requestUrl = 'api/v1/expense/addUpdateAccCodeWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.saveExpenseWeb = async function (data, callback) {
  const requestUrl = 'api/v1/expense/saveExpenseWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.getExpenseList = async function (data, callback) {
  const requestUrl = 'api/v1/expense/expenseListWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.getExpenseDetail = async function (data, callback) {
  const requestUrl = 'api/v1/expense/detailWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.getExpenseDelete = async function (data, callback) {
  const requestUrl = 'api/v1/expense/deleteExpnseWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.ExpenseMarkPaid = async function (data, callback) {
  const requestUrl = 'api/v1/expense/markPaidWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.DeleteCode = async function (data, callback) {
  const requestUrl = '/api/v1/expense/deleteAccCodeWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.CompleteAssessment  = async function (data, callback) {
  const requestUrl = '/api/v1/request/completeReqAssWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.RequestArchive  = async function (data, callback) {
  const requestUrl = '/api/v1/request/requestArchiveWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.DeleteReqAssessment  = async function (data, callback) {
  const requestUrl = '/api/v1/request/deleteAssessmentWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.GetTaskListingForList  = async function (data, callback) {
  const requestUrl = '/api/v1/task/show/listWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.GetTaskList  = async function (data, callback) {
  const requestUrl = '/api/v1/task/listWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.GetUserTaskList  = async function (data, callback) {
  const requestUrl = '/api/v1/task/userTaskListWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.SaveTaskDetail  = async function (data, callback) {
  const requestUrl = '/api/v1/task/saveWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.UpdateTaskDetail  = async function (data, callback) {
  const requestUrl = '/api/v1/task/updateWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.DeleteTask  = async function (data, callback) {
  const requestUrl = '/api/v1/task/deleteWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.completeTask  = async function (data, callback) {
  const requestUrl = '/api/v1/task/completeWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.RequestDragDropEvent  = async function (data, callback) {
  const requestUrl = '/api/v1/request/requestDateChangeWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
// END USER API NEW
MyJobApi.prototype.GetEndUserJobListWeb  = async function (data, callback) {
  const requestUrl = '/api/v1/endCustomer/job/listWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.GetEndUserJobNotesList  = async function (data, callback) {
  const requestUrl = '/api/v1/endCustomer/notes/listWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.GetEndUserQuoteList  = async function (data, callback) {
  const requestUrl = '/api/v1/endCustomer/quote/listWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.GetEndUserInvoiceList  = async function (data, callback) {
  const requestUrl = '/api/v1/endCustomer/invoice/listWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.GetEndUserVisitList  = async function (data, callback) {
  const requestUrl = '/api/v1/endCustomer/visit/listWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.GetEndUserPaymentList  = async function (data, callback) {
  const requestUrl = '/api/v1/endCustomer/payment/listWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.SendEndUserOtp  = async function (data, callback) {
  const requestUrl = '/api/v1/endCustomer/auth/sendOTP'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.reSendEndUserOtp  = async function (data, callback) {
  const requestUrl = '/api/v1/endCustomer/auth/resendOTP'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.VarifyAddedOTPEndUSer  = async function (data, callback) {
  const requestUrl = '/api/v1/endCustomer/auth/verifyOTP'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.AddDeleteCheckListInVisit  = async function (data, callback) {
  const requestUrl = '/api/v1/job/addOrDeleteVisitCheckListWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.EmailVarifyLogin  = async function (data, callback) {
  const requestUrl = '/api/v1/auth/owner/emailVerify'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.otpVarify  = async function (data, callback) {
  const requestUrl = '/api/v1/auth/owner/otpVerifyWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.ChangeUserEmail  = async function (data, callback) {
  const requestUrl = '/api/v1/auth/owner/changeEmailWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.GetPhoneCountryCode  = async function (data, callback) {
  const requestUrl = '/api/v1/preference/getCountryList'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.GetIndustryDetail  = async function (data, callback) {
  const requestUrl = '/api/v1/preference/getIndustries'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.DeleteAttachmentWeb  = async function (data, callback) {
  const requestUrl = '/api/v1/notes/deleteAttachmentWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.invoiceListOfVisitsAndJobWeb  = async function (data, callback) {
  const requestUrl = '/api/v1/job/invoiceListOfVisitsAndJobWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.GenerateJobShareLink  = async function (data, callback) {
  const requestUrl = '/api/v1/job/changeLinkExpiryWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.GenerateRequestShareLink  = async function (data, callback) {
  const requestUrl = '/api/v1/request/changeLinkExpiryWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.CheckJobTokenLink  = async function (data, callback) {
  const requestUrl = '/api/v1/endCustomer/checkToken'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.UploadJobTokenLinkAtt  = async function (data, callback) {
  const requestUrl = '/api/v1/endCustomer/job/saveAttachments'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.UploadRequestLinkAtt  = async function (data, callback) {
  const requestUrl = '/api/v1/endCustomer/job/requestAttachmentSave'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

// Get rquest

MyJobApi.prototype.DashboardDetailApi = async function (data, callback) {
  const requestUrl = 'api/v1/dashboard/overview/adminWeb'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.DashboardOverview = async function (data, callback) {
  const requestUrl = 'api/v1/dashboard/overviewWeb'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.DashboardAssignmentListApi = async function (data, callback) {
  const requestUrl = 'api/v1/dashboard/overview/meAssignmentsWeb'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.DashboardTaskListApi = async function (data, callback) {
  const requestUrl = 'api/v1/dashboard/overview/meTaskWeb'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.getPyamentDetail = async function (data, callback) {
  const requestUrl = '/api/v1/payment/detailWeb'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.getCustBasicDetail = async function (data, callback) {
  const requestUrl = '/api/v1/customer/detailBasicWeb'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}

MyJobApi.prototype.getPaymentDetail = async function (data, callback) {
  const requestUrl = '/api/v1/invoice/detailForPaymentWeb'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.getLeadStatusList = async function (data, callback) {
  const requestUrl = '/api/v1/lead/statusListWeb'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.getQuotationStatusList = async function (data, callback) {
  const requestUrl = '/api/v1/quotation/statusListWeb'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.paymentDetailForEdit = async function (data, callback) {
  const requestUrl = '/api/v1/payment/detailForEditWeb'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.deletePayment = async function (data, callback) {
  const requestUrl = '/api/v1/payment/deleteWeb'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.deleteInvoice = async function (data, callback) {
  const requestUrl = '/api/v1/invoice/deleteWeb'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.getEntityList = async function (data, callback) {
  const requestUrl = '/api/v1/preference/getEntityList'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.getEmployeeList = async function (data, callback) {
  const requestUrl = '/api/v1/employee/listWeb'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.getSettingItemsDetail = async function (data, callback) {
  const requestUrl = '/api/v1/setting/item/detailWeb'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.getSettingEmployeeList = async function (data, callback) {
  const requestUrl = '/api/v1/employee/listWeb'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.getSettingEmployeeDetail = async function (data, callback) {
  const requestUrl = '/api/v1/employee/detailWeb'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.getJobDetail = async function (data, callback) {
  const requestUrl = '/api/v1/job/detailWeb'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.getvisitLIst = async function (data, callback) {
  const requestUrl = 'api/v1/job/visitsWeb'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.getNotesLIst = async function (data, callback) {
  const requestUrl = 'api/v1/notes/listWeb'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.getFullImage = async function (data, callback) {
  const requestUrl = 'api/v1/notes/getImageWeb'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.getSendBookConfData = async function (data, callback) {
  const requestUrl = '/api/v1/email/defaultBodyDataWeb'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.getVisitDetail = async function (data, callback) {
  const requestUrl = '/api/v1/job/visitDetailWeb'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.getVisitItemList = async function (data, callback) {
  const requestUrl = '/api/v1/job/visitsItemListWeb'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}

MyJobApi.prototype.specificShiftDetail = async function (data, callback) {
  const requestUrl = 'api/v1/shift/detailWeb'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}

MyJobApi.prototype.getSpecificShiftLocation = async function (data, callback) {
  const requestUrl = 'api/v1/location/shiftLocationWeb'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.getBreakList = async function (data, callback) {
  const requestUrl = '/api/v1/preference/breakListWeb'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.getJobLocation = async function (data, callback) {
  const requestUrl = '/api/v1/location/jobLocationWeb'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.getLogedTIme = async function (data, callback) {
  const requestUrl = '/api/v1/job/loggedTimeWeb'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.getJobTimeLogUsers = async function (data, callback) {
  const requestUrl = '/api/v1/job/jobTimeLogUsersWeb'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.getOrgDetail = async function (data, callback) {
  const requestUrl = '/api/v1/organization/getOrgDetailWeb'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.GetLeadDetail = async function (data, callback) {
  const requestUrl = '/api/v1/lead/detailWeb'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.GetBasicLeadDetail = async function (data, callback) {
  const requestUrl = 'api/v1/lead/detailBasicWeb'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.getOrgQrCode = async function (data, callback) {
  const requestUrl = '/api/v1/organization/getQRWeb'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.getLeadCustEmailNumCheck = async function (data, callback) {
  const requestUrl = '/api/v1/customer/check/emailAndNumberExistWeb'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}

MyJobApi.prototype.getUnscheduleDetail = async function (data, callback) {
  const requestUrl = 'api/v1/job/jobAssigneeAndItemsWeb'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}

MyJobApi.prototype.getRequestDetail = async function (data, callback) {
  const requestUrl = '/api/v1/request/detailWeb'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.GetTaskDetail  = async function (data, callback) {
  const requestUrl = '/api/v1/task/detailWeb'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.GetPlanListing  = async function (data, callback) {
  const requestUrl = '/api/v1/planAndFeatures/plan/listWeb'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.GetPlanCurrencyList  = async function (data, callback) {
  const requestUrl = '/api/v1/planAndFeatures/currencyList'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.GetLogedInEmployeePermissions  = async function (data, callback) {
  const requestUrl = '/api/v1/subscription/plan/detailWeb'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.GetUsedStorageDetail  = async function (data, callback) {
  const requestUrl = '/api/v1/organization/getOrgUsedStorageWeb'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.GetCurrentPlanDetail  = async function (data, callback) {
  const requestUrl = '/api/v1/subscription/currentPlan'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.SettingCheckListItemDetail  = async function (data, callback) {
  const requestUrl = '/api/v1/setting/checkListItem/detailWeb'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}

// END USER API NEW

MyJobApi.prototype.GetJobDetailLinkHash  = async function (data, callback) {
  const requestUrl = '/api/v1/endCustomer/job/detailWeb'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.GetQuoteDetailLinkHash  = async function (data, callback) {
  const requestUrl = '/api/v1/endCustomer/quote/detailWeb'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.GetInvoiceDetailLinkHash  = async function (data, callback) {
  const requestUrl = '/api/v1/endCustomer/invoice/detailWeb'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.GetVisitDetailLinkHash  = async function (data, callback) {
  const requestUrl = '/api/v1/endCustomer/visit/detailWeb'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.TokenVerified  = async function (data, callback) {
  const requestUrl = '/api/v1/endCustomer/auth/verifyTokenWeb'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.GetChecklistInVisitDetail  = async function (data, callback) {
  const requestUrl = '/api/v1/job/visitCheckListWeb'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.GetAttachmentOverview  = async function (data, callback) {
  const requestUrl = '/api/v1/notes/GetAllAttachOverviewWeb'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.GetAttachmentList = async function (data, callback) {
  const requestUrl = '/api/v1/notes/GetAllAttachmentWeb'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.ShiftRealTimeView = async function (data, callback) {
  const requestUrl = '/api/v1/shift/realTimeViewWeb'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}
// Skadam added below July-3-2k24
MyJobApi.prototype.SaveToolsData = async function (data, callback) {
  const requestUrl = '/api/v1/setting/tool/saveWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.GetToolsList = async function (data, callback) {
  const requestUrl = '/api/v1/setting/tool/listWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.updateVisitToolPicWeb = async function (data, callback) {
  const requestUrl = '/api/v1/job/updateVisitToolsWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.DeleteToolDetail = async function (data, callback) {
  const requestUrl = '/api/v1/setting/tool/deleteToolDetailWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

MyJobApi.prototype.JobCategorySave = async function (data, callback) {
  const requestUrl = '/api/v1/setting/jobCategory/saveWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

MyJobApi.prototype.JobCategoryList = async function (data, callback) {
  const requestUrl = '/api/v1/setting/jobCategory/listWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

MyJobApi.prototype.DeleteJobCatagory = async function (data, callback) {
  const requestUrl = '/api/v1/setting/jobCategory/deleteWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

MyJobApi.prototype.GetNotesCategories = async function (data, callback) {
  const requestUrl = '/api/v1/notes/ctegorieslistWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

MyJobApi.prototype.AddUpdateNoteCategory = async function (data, callback) {
  const requestUrl = '/api/v1/notes/addUpdateCategoryWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

MyJobApi.prototype.DeleteNoteCategory = async function (data, callback) {
  const requestUrl = '/api/v1/notes/deleteCategiryWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

MyJobApi.prototype.UpdateNoteCategoryOrder = async function (data, callback) {
  const requestUrl = '/api/v1/notes/updateNoteCategoryOrderWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

MyJobApi.prototype.GetJobTypeList = async function (data, callback) {
  const requestUrl = '/api/v1/setting/jobType/listWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

MyJobApi.prototype.SaveJobTypeDetail = async function (data, callback) {
  const requestUrl = '/api/v1/setting/jobType/saveWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.SaveEmailConf = async function (data, callback) {
  const requestUrl = '/api/v1/setting/emailConfig/save'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.SendTestEmail = async function (data, callback) {
  const requestUrl = '/api/v1/setting/emailConfig/sendTestEmail'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.EmailConfList = async function (data, callback) {
  const requestUrl = '/api/v1/setting/emailConfig/list'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.GetEmailConfDetail = async function (data, callback) {
  const requestUrl = '/api/v1/setting/emailConfig/detail'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.GetTwilioList = async function (data, callback) {
  const requestUrl = '/api/v1/setting/twilioConfig/list'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.TwilioConfigSave = async function (data, callback) {
  const requestUrl = '/api/v1/setting/twilioConfig/save'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.TwilioConfigDetail = async function (data, callback) {
  const requestUrl = '/api/v1/setting/twilioConfig/detail'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.GetPhoneBasicDetail = async function (data, callback) {
  const requestUrl = '/api/v1/customer/getRequireBasicInfoWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.getSmsList = async function (data, callback) {
  const requestUrl = '/api/v1/sms/listWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
MyJobApi.prototype.SendTextMessage = async function (data, callback) {
  const requestUrl = '/api/v1/sms/sendWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

MyJobApi.prototype.CheckJobTypeLinked = async function (data, callback) {
  const requestUrl = '/api/v1/setting/jobType/checkTypeLinkWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

MyJobApi.prototype.DeleteJobType = async function (data, callback) {
  const requestUrl = '/api/v1/setting/jobType/deleteWeb'
  const contentType = 'application/json'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

MyJobApi.prototype.GetManageStorageOverviewDetail = async function (data, callback) {
  const requestUrl = '/api/v1/notes/GetAllAttachOverviewWeb'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}

MyJobApi.prototype.GetAllAttachment = async function (data, callback) {
  const requestUrl = '/api/v1/notes/GetAllAttachmentWeb'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}

export default MyJobApi
