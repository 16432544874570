import Layout from '@/View/layout/layout.vue'
// 
export const HRM = {
  path: '/',
  component: Layout,
  name: 'Layout',
  redirect: '/dashboard',
  meta: {
    title: 'Dashboard',
    loadPage: 'dashboard',
    breadCrumb: ''
  },
  children: [
    { // Dashboard object start
      path: '/dashboard',
      component: () => import('@/View/Dashboard/dashboard.vue'),
      hidden: true,
      name: 'dashboard1',
      meta: {
        title: 'Dashboard',
        selected: false,
        loadPage: 'dashboard',
        permissionList: []
      }
    },
    { // Dashboard object start
      path: '/olddashboard',
      component: () => import('@/View/Dashboard/OldDashboadCopy.vue'),
      hidden: true,
      name: 'olddashboard',
      meta: {
        title: 'olddashboard',
        selected: false,
        loadPage: 'dashboard',
        permissionList: []
      }
    },
    { // Dashboard object start
      path: '/splash',
      component: () => import('@/View/Splash/index.vue'),
      hidden: true,
      name: 'splash',
      meta: {
        title: 'ZizbeyJobs',
        selected: false,
        permissionList: [],
        loadPage: 'splash',
      }
    },
    // New FLow EndUser
    {
      path: '/organization',
      name: 'OrgDetail',
      component: () => import('@/View/organization/detail.vue'),
      hidden: true,
      meta: {
        title: 'Organization Detail',
        selected: false,
        permissionList: [],
        loadPage: 'Setting',
      }
    },
    {
      path: '/feedback',
      name: 'Feedback',
      component: () => import('@/View/sendRequest/feedback.vue'),
      hidden: true,
      meta: {
        title: 'Feedback',
        selected: false,
        permissionList: [],
        loadPage: 'Feedback',
      }
    },
    {
      path: '/support',
      name: 'Support',
      component: () => import('@/View/sendRequest/support.vue'),
      hidden: true,
      meta: {
        title: 'Support',
        selected: false,
        permissionList: [],
        loadPage: 'Support',
      }
    },
    {
      path: '/subscription',
      name: 'SubscriptionDetail',
      component: () => import('@/View/plan/detail.vue'),
      hidden: true,
      meta: {
        title: 'Subscription Detail',
        selected: false,
        permissionList: [],
        loadPage: 'SubscriptionDetail',
      }
    },
    {
      path: '/subscription/managestorage',
      name: 'SubscriptionStorage',
      component: () => import('@/View/plan/storagemanage.vue'),
      hidden: true,
      meta: {
        title: 'Manage Storage',
        selected: false,
        permissionList: [],
        loadPage: 'SubscriptionStorage',
      }
    },
    {
      path: '/subscription/managestorage/list',
      name: 'SubscriptionStorageList',
      component: () => import('@/View/plan/storageList.vue'),
      hidden: true,
      meta: {
        title: 'Storage List',
        selected: false,
        permissionList: [],
        loadPage: 'SubscriptionStorageList',
      }
    },
    { // customer object start
      path: '/customer',
      component: () => import('@/View/customer/main.vue'),
      hidden: false,
      name: 'customer',
      redirect: '/customer-List',
      meta: {
        title: 'Customer',
        selected: false,
        permissionList: [],
        loadPage: 'customer',
      },
      children: [
        {
          path: '/customerlist',
          name: 'customer',
          component: () => import('@/View/customer/customers.vue'),
          hidden: true,
          meta: {
            title: 'Customer List',
            selected: false,
            permissionList: [],
            loadPage: 'customer',
          }
        },
        {
          path: '/customer/details/:customerId',
          name: 'customerDetails',
          component: () => import('@/View/customer/details.vue'),
          hidden: true,
          meta: {
            title: 'Customer Detail',
            selected: false,
            permissionList: [],
            loadPage: 'customer',
          }
        }
      ]
    },
    { // Schedule object start
      path: '/schedule',
      component: () => import('@/View/schedule/main.vue'),
      hidden: false,
      name: 'schedule',
      redirect: '/schedule/dashboard',
      meta: {
        title: 'Schedule',
        selected: false,
        permissionList: [],
        loadPage: 'schedule',
      },
      children: [
        {
          path: '/schedule/dashboard',
          name: 'schedule',
          component: () => import('@/View/schedule/newDahboard.vue'),
          hidden: true,
          meta: {
            title: 'schedule Dashboard',
            selected: false,
            permissionList: [],
            loadPage: 'schedule',
          }
        },
        {
          path: '/schedule/monthview',
          name: 'monthView',
          component: () => import('@/View/schedule/dashboard.vue'),
          hidden: true,
          meta: {
            title: 'schedule Dashboard',
            selected: false,
            permissionList: [],
            loadPage: 'schedule',
          }
        },
        {
          path: '/schedule/addJob',
              name: 'scheduleAddJob',
              component: () => import('@/View/schedule/components/scheduleAddJob.vue'),
              hidden: true,
              meta: {
                title: 'schedule Add Job',
                selected: false,
                permissionList: [],
                loadPage: 'schedule',
              }
          },
      ]
    },
    { // Schedule object start
      path: '/visit',
      component: () => import('@/View/visit/main.vue'),
      hidden: false,
      name: 'visit',
      redirect: '/visitList',
      meta: {
        title: 'visit',
        selected: false,
        permissionList: [],
        loadPage: 'visit',
      },
      children: [
        {
          path: '/visitList',
          name: 'visit',
          component: () => import('@/View/visit/visitList.vue'),
          hidden: true,
          meta: {
            title: 'visit List',
            selected: false,
            permissionList: [],
            loadPage: 'visit',
          }
        },
      ]
    },
    { // Leads object start
      path: '/lead',
      component: () => import('@/View/lead/main.vue'),
      hidden: false,
      name: 'lead',
      redirect: '/lead-List',
      meta: {
        title: 'Lead',
        selected: false,
        permissionList: [],
        loadPage: 'lead',
      },
      children: [
        {
          path: '/leadlist',
          name: 'lead',
          component: () => import('@/View/lead/leads.vue'),
          hidden: true,
          meta: {
            title: 'Lead List',
            selected: false,
            permissionList: [],
            loadPage: 'lead',
          }
        },
        {
          path: '/lead/details/:leadId',
          name: 'LeadDetail',
          component: () => import('@/View/lead/leadDetail.vue'),
          hidden: true,
          meta: {
            title: 'Lead Detail',
            selected: false,
            permissionList: [],
            loadPage: 'lead',
          }
        },
      ]
    },
    { // Leads object start
      path: '/quotation',
      component: () => import('@/View/quotation/main.vue'),
      hidden: false,
      name: 'quotation',
      redirect: '/quotation-List',
      meta: {
        title: 'Quotation',
        selected: false,
        permissionList: [],
        loadPage: 'quotation',
      },
      children: [
        {
          path: '/quotationlist',
          name: 'quotation',
          component: () => import('@/View/quotation/quotations.vue'),
          hidden: true,
          meta: {
            title: 'Quotation List',
            selected: false,
            permissionList: [],
            loadPage: 'quotation',
          }
        },
        {
          path: '/quotation/createQuote/:quoteId',
          name: 'CreateQuote',
          component: () => import('@/View/quotation/components/createQuote.vue'),
          hidden: true,
          meta: {
            title: 'Add Quotation',
            selected: false,
            permissionList: [],
            loadPage: 'quotation',
          }
        },
        {
          path: '/quotation/editQuote/:quoteId',
          name: 'EditQuote',
          component: () => import('@/View/quotation/components/createQuote.vue'),
          hidden: true,
          meta: {
            title: 'Edit Quotation',
            selected: false,
            permissionList: [],
            loadPage: 'quotation',
          }
        },
        {
          path: '/quotation/:quoteId',
          name: 'quoteDetail',
          component: () => import('@/View/quotation/quoteDetail.vue'),
          hidden: true,
          meta: {
            title: 'Quotation Detail',
            selected: false,
            permissionList: [],
            loadPage: 'quotation',
          }
        }
      ]
    },
    { // Invoice object start
      path: '/invoice',
      component: () => import('@/View/invoice/main.vue'),
      hidden: false,
      name: 'invoice',
      redirect: '/invoice-List',
      meta: {
        title: 'Invoice',
        selected: false,
        permissionList: [],
        loadPage: 'invoice',
      },
      children: [
        {
          path: '/invoicelist',
          name: 'invoice',
          component: () => import('@/View/invoice/invoice.vue'),
          hidden: true,
          meta: {
            title: 'Invoice list',
            selected: false,
            permissionList: [],
            loadPage: 'invoice',
          }
        },
        {
          path: '/invoice/details/:invoiceId',
          name: 'invoiceDetails',
          component: () => import('@/View/invoice/invoiceDetail.vue'),
          hidden: true,
          meta: {
            title: 'Invoice Details',
            selected: false,
            permissionList: [],
            loadPage: 'invoice',
          }
        },
        {
          path: '/invoice/createInvoice',
          name: 'createInvoice',
          component: () => import('@/View/invoice/createInvoice.vue'),
          hidden: true,
          meta: {
            title: 'Create invoice',
            selected: false,
            permissionList: [],
            loadPage: 'invoice',
          }
        },
        {
          path: '/invoice/recordPayment',
          name: 'recordPayment',
          component: () => import('@/View/invoice/recordPayment.vue'),
          hidden: true,
          meta: {
            title: 'Record Payment',
            selected: false,
            permissionList: [],
            loadPage: 'invoice',
          }
        }
      ]
    },
    { // payment object start
      path: '/payment',
      component: () => import('@/View/payment/main.vue'),
      hidden: false,
      name: 'payment',
      redirect: '/paymentlist',
      meta: {
        title: 'Payment',
        selected: false,
        permissionList: [],
        loadPage: 'payment',
      },
      children: [
        {
          path: '/paymentlist',
          name: 'payment',
          component: () => import('@/View/payment/payment.vue'),
          hidden: true,
          meta: {
            title: 'Payment list',
            selected: false,
            permissionList: [],
            loadPage: 'payment',
          }
        },
        {
          path: '/payment/details/:paymentId',
          name: 'paymentDetails',
          component: () => import('@/View/payment/paymentDetail.vue'),
          hidden: true,
          meta: {
            title: 'Payment Detail',
            selected: false,
            permissionList: [],
            loadPage: 'payment',
          }
        }
      ]
    },
    { // job object start
      path: '/job',
      component: () => import('@/View/job/main.vue'),
      hidden: false,
      name: 'job',
      redirect: '/joblist',
      meta: {
        title: 'Job',
        selected: false,
        permissionList: [],
        loadPage: 'job',
      },
      children: [
        {
          path: '/joblist',
          name: 'job',
          component: () => import('@/View/job/jobs.vue'),
          hidden: true,
          meta: {
            title: 'Job List',
            selected: false,
            permissionList: [],
            loadPage: 'job',
          }
        },
        {
          path: '/job/details/:jobId',
          name: 'jobDetail',
          component: () => import('@/View/job/jobDetail.vue'),
          hidden: true,
          meta: {
            title: 'Job Details',
            selected: false,
            permissionList: [],
            loadPage: 'job',
          }
        },
        // {
        //   path: '/job/Add',
        //   name: 'addJob',
        //   component: () => import('@/View/job/addJob.vue'),
        //   hidden: true,
        //   meta: {
        //     title: 'Add New Job',
        //     selected: false,
        //     permissionList: [],
        //     loadPage: 'job',
        //   }
        // },
        {
          path: '/job/addNew',
          name: 'addNewJob',
          component: () => import('@/View/job/createJob.vue'),
          hidden: true,
          meta: {
            title: 'Create New Job',
            selected: false,
            permissionList: [],
            loadPage: 'job',
          }
        },
        {
          path: '/job/visit/detail/:visitId',
          name: 'visitDetail',
          component: () => import('@/View/job/visit/detail.vue'),
          hidden: true,
          meta: {
            title: 'Visit Detail',
            selected: false,
            permissionList: [],
            loadPage: 'visit',
          }
        }
      ]
    },
    { // Shift 
      path: '/shift',
      component: () => import('@/View/shift/shift.vue'),
      hidden: true,
      name: 'shift',
      meta: {
        title: 'shift',
        selected: false,
        loadPage: 'shift',
        permissionList: []
      }
    },
    { // Shift 
      path: '/shift/add',
      component: () => import('@/View/shift/addNewShift.vue'),
      hidden: true,
      name: 'AddShift',
      meta: {
        title: 'Add Shift',
        selected: false,
        loadPage: 'shift',
        permissionList: []
      }
    },
    { // Shift 
      path: '/shift/detail/:shiftId',
      component: () => import('@/View/shift/shiftDetail.vue'),
      hidden: true,
      name: 'shiftDetail',
      meta: {
        title: 'Shift Detail',
        selected: false,
        loadPage: 'shift',
        permissionList: []
      }
    },
    { // Dashboard object start
      path: '/activity',
      component: () => import('@/View/activity/activity.vue'),
      hidden: true,
      name: 'activity',
      meta: {
        title: 'activity',
        selected: false,
        loadPage: 'activity',
        permissionList: []
      }
    }, 
    { // Dashboard object start
      path: '/setting',
      component: () => import('@/View/setting/main.vue'),
      hidden: false,
      name: 'Setting',
      redirect: '/setting/dashboard',
      meta: {
        title: 'Setting',
        selected: false,
        permissionList: [],
        loadPage: 'Setting',
      },
      children: [
        {
          path: '/setting/dashboard',
          name: 'settingDashboard',
          component: () => import('@/View/setting/setting.vue'),
          hidden: true,
          meta: {
            title: 'Setting Dashboard',
            selected: false,
            permissionList: [],
            loadPage: 'Setting',
          }
        },
        {
          path: '/setting/items',
          name: 'Items',
          component: () => import('@/View/setting/items/index.vue'),
          hidden: true,
          meta: {
            title: 'Items',
            selected: false,
            permissionList: [],
            loadPage: 'Setting',
          }
        },
        {
          path: '/setting/items/detail/:itemId',
          name: 'SettingItemDetails',
          component: () => import('@/View/setting/items/list/settingitemDetails.vue'),
          hidden: true,
          meta: {
            title: 'Item Detail',
            selected: false,
            permissionList: [],
            loadPage: 'Setting',
          }
        },
        {
          path: '/setting/items/add/:itemId',
          name: 'SettingItemAdd',
          component: () => import('@/View/setting/items/list/addEditItem.vue'),
          hidden: true,
          meta: {
            title: 'Add Item',
            selected: false,
            permissionList: [],
            loadPage: 'Setting',
          }
        },
        {
          path: '/setting/items/edit/:itemId',
          name: 'SettingItemEdit',
          component: () => import('@/View/setting/items/list/addEditItem.vue'),
          hidden: true,
          meta: {
            title: 'Add Item',
            selected: false,
            permissionList: [],
            loadPage: 'Setting',
          }
        },
        {
          path: '/setting/tax',
          name: 'SettingTax',
          component: () => import('@/View/setting/tax/listing.vue'),
          hidden: true,
          meta: {
            title: 'Tax',
            selected: false,
            permissionList: [],
            loadPage: 'Setting',
          }
        },
        {
          path: '/setting/tools',
          name: 'SettingTools',
          component: () => import('@/View/setting/tools/listing.vue'),
          hidden: true,
          meta: {
            title: 'Tools',
            selected: false,
            permissionList: [],
            loadPage: 'Setting',
          }
        },
        {
          path: '/setting/jobcategory',
          name: 'SettingJobCategory',
          component: () => import('@/View/setting/jobCategory/listing.vue'),
          hidden: true,
          meta: {
            title: 'Job Category',
            selected: false,
            permissionList: [],
            loadPage: 'Setting',
          }
        },
        {
          path: '/setting/twilioconfiguration',
          name: 'Twilioconfiguration',
          component: () => import('@/View/setting/twilio/listing.vue'),
          hidden: true,
          meta: {
            title: 'Twilio Configuration',
            selected: false,
            permissionList: [],
            loadPage: 'Setting',
          }
        },
        {
          path: '/setting/emailconfiguration',
          name: 'Emailconfiguration',
          component: () => import('@/View/setting/emails/listing.vue'),
          hidden: true,
          meta: {
            title: 'Email Configuration',
            selected: false,
            permissionList: [],
            loadPage: 'Setting',
          }
        },
        {
          path: '/setting/emailconfiguration/detail/:emailId',
          name: 'EmailconfigurationDetail',
          component: () => import('@/View/setting/emails/detail.vue'),
          hidden: true,
          meta: {
            title: 'Email Configuration Detail',
            selected: false,
            permissionList: [],
            loadPage: 'Setting',
          }
        },
        {
          path: '/setting/emailconfiguration/add/:emailId',
          name: 'EmailconfigurationAdd',
          component: () => import('@/View/setting/emails/addemailConf.vue'),
          hidden: true,
          meta: {
            title: 'Email Configuration Add',
            selected: false,
            permissionList: [],
            loadPage: 'Setting',
          }
        },
        {
          path: '/setting/emailconfiguration/Edit/:emailId',
          name: 'EmailconfigurationEdit',
          component: () => import('@/View/setting/emails/addemailConf.vue'),
          hidden: true,
          meta: {
            title: 'Email Configuration Edit',
            selected: false,
            permissionList: [],
            loadPage: 'Setting',
          }
        },
        {
          path: '/setting/notescategory',
          name: 'SettingNotesCategory',
          component: () => import('@/View/setting/notesCategory/listing.vue'),
          hidden: true,
          meta: {
            title: 'Notes Category',
            selected: false,
            permissionList: [],
            loadPage: 'Setting',
          }
        },
        {
          path: '/setting/jobtype',
          name: 'SettingJobType',
          component: () => import('@/View/setting/jobType/listing.vue'),
          hidden: true,
          meta: {
            title: 'Job Type',
            selected: false,
            permissionList: [],
            loadPage: 'Setting',
          }
        },
        {
          path: '/setting/storage',
          name: 'SettingStorage',
          component: () => import('@/View/setting/storage/listing.vue'),
          hidden: true,
          meta: {
            title: 'Storage',
            selected: false,
            permissionList: [],
            loadPage: 'Setting',
          }
        },
        {
          path: '/setting/payment',
          name: 'SettingPaymentMethod',
          component: () => import('@/View/setting/payMethod/paymentList.vue'),
          hidden: true,
          meta: {
            title: 'Payment Method',
            selected: false,
            permissionList: [],
            loadPage: 'Setting',
          }
        },
        {
          path: '/setting/employee',
          name: 'SettingEmployees',
          component: () => import('@/View/setting/employee/employees.vue'),
          hidden: true,
          meta: {
            title: 'Employees',
            selected: false,
            permissionList: [],
            loadPage: 'Setting',
          }
        },
        {
          path: '/setting/employee/detail/:empId',
          name: 'SettingEmployeesDetail',
          component: () => import('@/View/setting/employee/detail.vue'),
          hidden: true,
          meta: {
            title: 'Detail',
            selected: false,
            permissionList: [],
            loadPage: 'Setting',
          }
        },
        {
          path: '/setting/employee/add/:empId',
          name: 'SettingEmployeesAdd',
          component: () => import('@/View/setting/employee/addNewEmp.vue'),
          hidden: true,
          meta: {
            title: 'Add Employee',
            selected: false,
            permissionList: [],
            loadPage: 'Setting',
          }
        },
        {
          path: '/setting/employee/edit/:empId',
          name: 'SettingEmployeesEdit',
          component: () => import('@/View/setting/employee/editEmployee.vue'),
          hidden: true,
          meta: {
            title: 'Edit Employee',
            selected: false,
            permissionList: [],
            loadPage: 'Setting',
          }
        },
        {
          path: '/setting/location',
          name: 'SettingLocation',
          component: () => import('@/View/setting/location/location.vue'),
          hidden: true,
          meta: {
            title: 'Location',
            selected: false,
            permissionList: [],
            loadPage: 'Setting',
          }
        },
        {
          path: '/setting/accountingCode',
          name: 'AccountingCode',
          component: () => import('@/View/setting/accountingCode/listing.vue'),
          hidden: true,
          meta: {
            title: 'AccountingCode',
            selected: false,
            permissionList: [],
            loadPage: 'Setting',
          }
        },
        {
          path: '/setting/checkList',
          name: 'CheckListItem',
          component: () => import('@/View/setting/checkList/index.vue'),
          hidden: true,
          meta: {
            title: 'CheckListItem',
            selected: false,
            permissionList: [],
            loadPage: 'Setting',
          }
        },
      ]
    },
    { // Request object start
      path: '/request',
      component: () => import('@/View/request/main.vue'),
      hidden: true,
      name: 'request',
      redirect: '/requestList',
      meta: {
        title: 'Request',
        selected: false,
        permissionList: [],
        loadPage: 'request',
      },
      children: [
        {
          path: '/requestList',
          name: 'request',
          component: () => import('@/View/request/request.vue'),
          hidden: true,
          meta: {
            title: 'Request List',
            selected: false,
            permissionList: [],
            loadPage: 'request',
          }
        },
        {
          path: '/request/details/:requestId',
          name: 'requestDetail',
          component: () => import('@/View/request/detail.vue'),
          hidden: true,
          meta: {
            title: 'Request Details',
            selected: false,
            permissionList: [],
            loadPage: 'request',
          }
        },
        {
          path: '/request/addrequest/:requestId',
          name: 'addRequest',
          component: () => import('@/View/request/components/addUpdate.vue'),
          hidden: true,
          meta: {
            title: 'Add Request Details',
            selected: false,
            permissionList: [],
            loadPage: 'request',
          }
        },
        {
          path: '/request/editrequest/:requestId',
          name: 'editRequest',
          component: () => import('@/View/request/components/addUpdate.vue'),
          hidden: true,
          meta: {
            title: 'Edit Request Details',
            selected: false,
            permissionList: [],
            loadPage: 'request',
          }
        },
      ]
    },
    { // Request object start
      path: '/task',
      component: () => import('@/View/task/main.vue'),
      hidden: true,
      name: 'Task',
      redirect: '/tasks',
      meta: {
        title: 'Task',
        selected: false,
        permissionList: [],
        loadPage: 'Task',
      },
      children: [
        {
          path: '/tasks',
          name: 'Tasks',
          component: () => import('@/View/task/tasks.vue'),
          hidden: true,
          meta: {
            title: 'Task List',
            selected: false,
            permissionList: [],
            loadPage: 'Task',
          }
        },
        {
          path: '/task/add/:taskId',
          name: 'AddNewTask',
          component: () => import('@/View/task/component/addTask/addNewTask.vue'),
          hidden: true,
          meta: {
            title: 'Add Task',
            selected: false,
            permissionList: [],
            loadPage: 'Task',
          }
        },
        {
          path: '/task/edit/:taskId',
          name: 'UpdateNewTask',
          component: () => import('@/View/task/component/updateTask/updateTask.vue'),
          hidden: true,
          meta: {
            title: 'Update Task',
            selected: false,
            permissionList: [],
            loadPage: 'Task',
          }
        },
        {
          path: '/task/:taskId',
          name: 'TaskDetail',
          component: () => import('@/View/task/component/detail.vue'),
          hidden: true,
          meta: {
            title: 'Task Detail',
            selected: false,
            permissionList: [],
            loadPage: 'Task',
          }
        },
      ]
    },
    { // Request object start
      path: '/expense',
      component: () => import('@/View/expense/main.vue'),
      hidden: true,
      name: 'Expense',
      redirect: '/expenseList',
      meta: {
        title: 'Expense',
        selected: false,
        permissionList: [],
        loadPage: 'Expense',
      },
      children: [
        {
          path: '/expenseList',
          name: 'Expense',
          component: () => import('@/View/expense/listing.vue'),
          hidden: true,
          meta: {
            title: 'Expense List',
            selected: false,
            permissionList: [],
            loadPage: 'Expense',
          }
        },
        {
          path: '/expense/detail/:expId',
          name: 'ExpenseDetail',
          component: () => import('@/View/expense/components/detail.vue'),
          hidden: true,
          meta: {
            title: 'Detail',
            selected: false,
            permissionList: [],
            loadPage: 'Expense',
          }
        },
        {
          path: '/expense/add/:expId',
          name: 'AddExpense',
          component: () => import('@/View/expense/components/addUpdate.vue'),
          hidden: true,
          meta: {
            title: 'Add Expense',
            selected: false,
            permissionList: [],
            loadPage: 'Expense',
          }
        },
        {
          path: '/expense/edit/:expId',
          name: 'EditExpense',
          component: () => import('@/View/expense/components/addUpdate.vue'),
          hidden: true,
          meta: {
            title: 'Edit Expense',
            selected: false,
            permissionList: [],
            loadPage: 'Expense',
          }
        },
      ]
    },
  ]
}
